<template>
  <header role="banner">
    <div class="float-right">
      <I18n />
    </div>
    <h1>
      <router-link
        :to="`/${$t('lang')}/`"
        class="text-decoration-none"
      >
        <b class="violet">Frama</b><b class="orange">troll</b>
      </router-link>
    </h1>
    <hr
      class="trait"
      role="presentation"
    />
  </header>
</template>

<script>
import I18n from '../../commons/components/I18n.vue';

export default {
  components: {
    I18n,
  },
};
</script>

<style lang="scss">
  #header {
    background: #fff;
    border-bottom: 1px solid var(--f-g4);

    h1 {
      margin: 0;
      line-height: normal;
      a {
        text-decoration: none;
      }
    }

    .btn {
      border: transparent;
    }
  }
</style>
